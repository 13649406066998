export default class Player {
    /**
     * プレーヤーの名前を取得/設定します。
     */
    public Name = '';

    /**
     * プレーヤーのポイントIDを取得/設定します。
     */
    public Point = 0;

    constructor(name: string) {
        this.Name = name;
    }
}