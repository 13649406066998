import Member from '@/models/Member';
import Player from '@/models/Player';

/**
 * 対戦順を生成するクラスです。
 */
export default class RoundRobin {
    /**
     * 対戦順の生成を行います。
     * @param members 対戦者リストを指定します。
     * @param n 1つ組み合わせの数を指定します。
     * @returns 対戦リストを返します。
     */
    public Create(members: Array<Member>, n: number): Array<Array<Player>> {
        const memberLength = members.length;

        const idList = this.CreateList(memberLength);
        const combinationList = this.Combination(idList, n);

        this.Sort(combinationList);
        const ret = new Array<Array<Player>>();

        combinationList.forEach((element) => {
            const players = new Array<Player>();
            element.forEach((id) => {
                const player = this.GetPlayer(id, members);
                players.push(player);
            });
            ret.push(players);
        });

        return ret;
    }

    /**
   * 対戦順の生成を行います。
   * @param members 対戦者リストを指定します。
   * @returns 対戦リストを返します。
   */
    public CreatePair(members: Array<Member>): Array<Array<Player>> {
        const n = members.length;

        const list = this.CreateList(n);
        const combinationList = this.PairCombination(list);

        const test = this.LocalSearch(combinationList);
        const answer = test.answer;

        console.log(`cost: ${test.cost}`);

        answer.forEach((value) => {
            console.log(this.ZeroPadding(value.toString(2), n));
        });

        const ret = new Array<Array<Player>>();

        for (let i = 0; i < answer.length; i++) {
            const bits = this.ZeroPadding(answer[i].toString(2), n);
            const players = this.GetPlayers(bits, members);

            ret.push(players);
        }

        return ret;
    }

    /**
     * 連戦の評価行います
     * @param list 評価を行う対戦順を指定します。
     * @returns 評価を返します。
     */
    private EvaluationFunc(list: Array<number>) {
        const ret = list.reduce((cost, _, idx, src) => src[idx] & src[idx - 1] ? ++cost : cost, 0);
        return ret;
    }

    /**
     * 対戦順を入れ替えた評価の差分を取得します。
     * @param list 評価を行う対戦順を指定します。
     * @param i 入れ替え元の要素を指定します。
     * @param j 入れ替え先の要素を指定します。
     * @returns 差分評価を返します。
     */
    private GetSwapCost(list: Array<number>, i: number, j: number) {
        const getLocalCost = (x: number, y: number) => list[x] & list[y] ? 1 : 0;
        const costBefore = getLocalCost(i, i + 1) + getLocalCost(j, j + 1);
        const costAfter = getLocalCost(i, j) + getLocalCost(i + 1, j + 1);

        return costAfter - costBefore;
    }

    /**
     * 対戦順の繋ぎ変えを行います。
     * @param list 入れ替えを行う対戦順を指定します。
     * @param i 繋ぎ変え元の対戦要素を指定します。
     * @param j 繋ぎ変え先の対戦要素を指定します。
     * @returns 繋ぎ変えた対戦順を返します。
     */
    private SwapEdges(list: Array<number>, i: number, j: number) {
        const head = list.slice(0, i + 1);
        const reverseTarget = list.slice(i + 1, j + 1);
        const tail = list.slice(j + 1);
        return [...head, ...reverseTarget.reverse(), ...tail];
    }

    /**
     * 対戦順の改善を行います。
     * @param list 対戦順を指定します。
     */
    private Improve(list: Array<number>) {
        let iBest = 0;
        let jBest = 0;
        let diffBest = 0;
        for (let i = 0; i < list.length; i++) {
            for (let j = i + 2; j < list.length; j++) {
                const swapCost = this.GetSwapCost(list, i, j);
                if (swapCost <= diffBest) {
                    diffBest = swapCost;
                    [iBest, jBest] = [i, j];
                }
            }
        }
        return diffBest < 0 ? this.SwapEdges(list, iBest, jBest) : null;
    }

    /**
     * 最適な対戦順の探索を行います。
     * @param list 探索を行う対戦順を指定します。
     * @returns 探索結果を返します。
     */
    private LocalSearch(list: Array<number>) {
        const totalCost = this.EvaluationFunc(list);
        if (totalCost !== 0) {
            for (; ;) {
                const improvedList = this.Improve(list);
                if (!improvedList) break;
                list = improvedList;
            }
        }
        return {
            answer: list,
            cost: this.EvaluationFunc(list)
        }
    }

    /**
     * 指定した大きさの対戦IDリストを生成します。
     * @param n 参加人数を指定します。
     * @returns 生成された対戦IDリストを返します。
     */
    private CreateList(n: number) {
        const ret = [...Array(n)].map((_, i) => 1 << i);

        return ret;
    }

    /**
     * 対戦の組み合わせを生成します。
     * @param list 対戦IDリストを指定します。
     * @param n 1つ組み合わせの数を指定します。
     * @returns 対戦組み合わせリストを返します。
     */
    private Combination(list: Array<number>, n: number) {
        const ret = Array<Array<number>>();
        if (list.length < n) {
            return [];
        }
        if (n === 1) {
            for (let i = 0; i < list.length; i++) {
                ret[i] = [list[i]];
            }
        } else {
            for (let i = 0; i < list.length - n + 1; i++) {
                const row = this.Combination(list.slice(i + 1), n - 1);
                for (let j = 0; j < row.length; j++) {
                    ret.push([list[i]].concat(row[j]));
                }
            }
        }

        return ret;
    }

    /**
     * 対戦の組み合わせを生成します。
     * @param list 対戦IDリストを指定します。
     * @returns 対戦組み合わせリストを返します。
     */
    private PairCombination(list: Array<number>) {
        const combinationList = [];
        for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < i; j++) {
                combinationList.push(list[i] + list[j]);
            }
        }
        return combinationList;
    }

    /**
     * 対戦順を連戦が起こらないようにソートを行います。
     * @param list ソートを行う対戦リストを指定します。
     */
    private Sort(list: Array<Array<number>>) {
        for (let i = 0; i < list.length; i++) {
            const current = list[i].reduce((sum: number, x: number) => sum + x);
            let cost = 0;

            for (let j = i; j < list.length; j++) {
                const next = list[j].reduce((sum: number, x: number) => sum + x);
                const xor = current ^ next;
                const jCost = this.PopulationCount(xor);

                if (cost < jCost) {
                    cost = jCost;
                    if (i + 1 < list.length) {
                        const tempList = list[i + 1];
                        list[i + 1] = list[j];
                        list[j] = tempList;
                    }
                }
            }
        }

        console.log(list);
    }

    /**
     * ビットが立っている数を数えます。
     * @param target 数える値を指定します。
     * @returns ビットが立っている数を返します。
     */
    private PopulationCount(target: number) {
        const bin = target.toString(2);
        const count = (bin.match(/1/g) || []).length;

        return count;
    }

    /**
     * 対戦者IDから対戦者名を取得します。
     * @param bits ビットで表した対戦者IDを指定します。
     * @param members 対戦リストを指定します。
     * @returns 対戦者情報を返します。
     */
    private GetPlayer(id: number, members: Array<Member>) {
        const length = members.length;
        const bin = this.ZeroPadding(id.toString(2), length);

        const index = bin.indexOf('1')
        const memberIndex = length - 1 - index;

        const player = new Player(members[memberIndex].Name);

        return player;
    }

    /**
     * 対戦IDを文字列化します。
     * @param bits ビットで表した対戦IDを指定します。
     * @param members 対戦者リストを指定します。
     * @returns 対戦の文字列を返します。
     */
    private GetPlayers(bits: string, members: Array<Member>) {
        const players = new Array<Player>();
        let count = 0;

        for (let x = 0; x < bits.length; x++) {
            if (bits[x] == '1') {
                if (count == 0) {
                    const player = new Player(members[x].Name);
                    players.push(player);
                    count++;
                } else if (count > 0) {
                    const player = new Player(members[x].Name);
                    players.push(player);

                    //これ以上はループさせる必要あないので抜ける
                    break;
                }
            }
        }

        return players;
    }

    /**
   * ゼロ埋めを行います。
   * @param str ゼロ埋めを行う文字列を指定します。
   * @param n 桁数を指定します。
   * @returns ゼロ埋めされた文字列を返します。
   */
    private ZeroPadding(str: string, n: number) {
        let zero = '0';
        for (let i = 0; i < n; i++) {
            zero += '0';
        }

        const ret = (zero + str).slice(-n);

        return ret;
    }
}