export default class Member {
    /**
     * 参加者の名前を取得/設定します
     */
    public Name = '';

    /**
     * 参加者の合計ポイントを取得/設定します。
     */
    public TotalPoint = 0;

    constructor(name: string) {
        this.Name = name;
    }
}